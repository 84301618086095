$z-index-sizes: (
  globalLoader: 100,
  navbar: 3,
  pageNavbar: 2,
  base: 1,
  none: 0
);

/**
* Usage:
* .popup {
*   z-index: z-index(base);
* }
*/
@function z-index($size: base) {
  @if map-has-key($z-index-sizes, $size) {
    @return map-get($z-index-sizes, $size);
  } @else {
    @warn "Invalid z-index `#{$size}`";
  }
}
