/* Constants */
body {
  --offline-banner-height: 0px;
  --app-navbar-height: 75px;
  --app-bottom-bar-height: 85px;
  --max-app-width: 600px;

  /* Colors */
  --duke-yellow-orange: #e27908;
}

body.offline {
  --offline-banner-height: 25px;
  --app-navbar-height: calc(75px + var(--offline-banner-height, 0px));
}
