// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "@angular/material/theming";
// Plus imports for other components in your app.

// Uncomment to add ArcGIS styles
// @import "@arcgis/core/assets/esri/themes/light/main.css";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

@import "./sass/duke-material-theme";

@import "@angular/cdk/overlay-prebuilt.css";

@import "@de-electron/design-tokens/dist/web/__all";
@import "@de-electron/component-library/src/global/scss/base/_reset";
@import "@de-electron/component-library/src/global/scss/base/_typography";
@import "@de-electron/component-library/src/global/scss/base/_loader";
@import "@de-electron/component-library/src/global/scss/base/__imports";

@import "./sass/helpers";

@font-face {
  font-family: "News Gothic Regular";
  font-style: normal;
  src: url(./assets/fonts/newsgothicbt-roman.woff2) format("woff2");
}

body {
  @include font-scale(body);
}

html,
body {
  font-family: "News Gothic Regular", sans-serif;
  margin: 0em;
  padding: 0em;
  box-sizing: border-box;
  height: 100%;

  @media not print {
    overflow: hidden;
  }

  /* Smooth scrolling */
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

app-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f2f4f4;
}

*,
*:after,
*:before {
  font-family: "News Gothic Regular", sans-serif;
}

.mat-radio-button:not(.disabled).mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: color(teal, darker);
}

.mat-radio-button:not(.disabled).mat-accent .mat-radio-inner-circle {
  color: color(teal, darker);
  background-color: color(teal, darker);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background-color: #f2f4f4 !important;
}

// Visually hidden class from https://www.w3.org/WAI/tutorials/forms/labels/#note-on-hiding-elements
.visually-hidden {
  position: absolute;
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
}

*::-webkit-scrollbar {
  width: 0.8rem;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #b4b4b4;
  border-radius: border-radius(m);
  outline: none;
}

.cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane de-snackbar div span span {
  font-size: 1rem !important;
}

@media print {
  @page {
    margin: 0.15in !important;
    // Ensures page is in portrait when printing
    size: 8.5in 11in !important;
  }

  html {
    width: 8.5in;
    height: 11in;
  }

  * {
    // Preserves background colors and styling
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
}

.print {
  @media not print {
    display: none !important;
  }
}

.no-print {
  @media print {
    display: none !important;
  }
}